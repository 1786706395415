@import '../../App.scss';

.loading-wrapper {
	width: 100vw;
	height: 100vh;
	background-image: url('../../assets/Bgs/bg-conta.svg');
	background-repeat: no-repeat;
	background-size: cover;
	.loading {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			min-width: 250px;
			width: 250px;
			filter: drop-shadow($drop-shadow-sm);
			opacity: 1;
			-webkit-animation: fadeinout 1.5s linear forwards infinite;
			animation: fadeinout 1.5s linear forwards infinite;
		}
	}
}

@-webkit-keyframes fadeinout {
	0%,
	100% {
		opacity: 0.3;
	}
	50% {
		opacity: 1;
	}
}

@keyframes fadeinout {
	0%,
	100% {
		opacity: 0.3;
	}
	50% {
		opacity: 1;
	}
}
